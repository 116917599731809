import registryEntriesEndpoints from "./endpoints/registryEntries-endpoints";
import apiConfig from "../config/api";
import http from "./http";
import store from "../store/index";

const {
  getAllRegistryEntries,
  getDepartments,
  addRegistryEntry,
  getRegistryEntry,
  deleteRegistryEntry,
  editRegistryEntry,
} = registryEntriesEndpoints;
const { baseURL } = apiConfig;

const RegistryEntries = {
  async handleGetRegistryEntries(token, registryId) {
    const getAllRegistryEntriesUrl = `${baseURL}${getAllRegistryEntries.GET()}`;
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    return await http
      .get(
        getAllRegistryEntriesUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async addRegistryEntry(registryEntry) {
    const getAddRegistryEntryUrl = `${baseURL}${addRegistryEntry.GET}`;
    let permissions = store.state.permissions;
    let permission = permissions[0];
    return await http
      .post(
        getAddRegistryEntryUrl,
        {
          issuer: registryEntry.issuer,
          externalRegistrationNumber: registryEntry.externalRegistrationNumber,
          externalRegistrationDate: registryEntry.externalRegistrationDate,
          internalRegistrationNumber: registryEntry.internalRegistrationNumber,
          internalRegistrationDate: registryEntry.internalRegistrationDate,
          department: registryEntry.department,
          description: registryEntry.description,
          dispatchDate: registryEntry.dispatchDate,
          destination: registryEntry.destination,
          status: registryEntry.status,
          type: registryEntry.type,
          registryId: registryEntry.registryId,
          username: registryEntry.username,
          numberOfDaysToRespond: registryEntry.numberOfDaysToRespond,
          deadline: registryEntry.deadline,
          responseDescription: registryEntry.responseDescription,
          responseDate: registryEntry.responseDate,
          permission: permission[0],
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async handleEditRegistryEntry(registryEntry) {
    const getEditRegistryEntryUrl = `${baseURL}${editRegistryEntry.GET(
      registryEntry.id
    )}`;
    let permissions = store.state.permissions;
    let permission = permissions[0];
    return await http
      .put(
        getEditRegistryEntryUrl,
        {
          id: registryEntry.id,
          issuer: registryEntry.issuer,
          externalRegistrationNumber: registryEntry.externalRegistrationNumber,
          externalRegistrationDate: registryEntry.externalRegistrationDate,
          internalRegistrationNumber: registryEntry.internalRegistrationNumber,
          internalRegistrationDate: registryEntry.internalRegistrationDate,
          dispatchDate: registryEntry.dispatchDate,
          destination: registryEntry.destination,
          department: registryEntry.department,
          description: registryEntry.description,
          status: registryEntry.status,
          registryId: registryEntry.registryId,
          type: registryEntry.type,
          username: registryEntry.username,
          numberOfDaysToRespond: registryEntry.numberOfDaysToRespond,
          deadline: registryEntry.deadline,
          responseDescription: registryEntry.responseDescription,
          responseDate: registryEntry.responseDate,
          permission: permission[0],
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async handleGetRegistryEntry(id) {
    const getGetRegistryEntryUrl = `${baseURL}${getRegistryEntry.GET(id)}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .get(
        getGetRegistryEntryUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${store.state.token}`,
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },

  async getAllDepartments() {
    const getDepartmentsUrl = `${baseURL}${getDepartments.GET()}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .get(
        getDepartmentsUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${store.state.token}`,
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },

  async deleteRegistryEntry(id) {
    const getDeleteRegistryEntryUrl = `${baseURL}${deleteRegistryEntry.GET(
      id
    )}`;
    return await http
      .delete(
        getDeleteRegistryEntryUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
};

export default RegistryEntries;
