export default Object.freeze({
  getAllRegistryEntries: {
    GET: () => {
      return `registryEntries`;
    },
  },
  getDepartments: {
    GET: () => {
      return `registryEntries/departments`;
    },
  },
  addRegistryEntry: {
    GET: "registryEntries",
  },
  getRegistryEntry: {
    GET: (id) => {
      return `registryEntries/${id}`;
    },
  },
  editRegistryEntry: {
    GET: (id) => {
      return `registryEntries/${id}`;
    },
  },
  deleteRegistryEntry: {
    GET: (id) => {
      return `registryEntries/${id}`;
    },
  },
});
