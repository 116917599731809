import registryEndpoints from "./endpoints/registry-endpoints";
import apiConfig from "../config/api";
import http from "./http";
import store from "../store/index";

const {
  getRegistries,
  addReservedNumbers,
  getReservedNumbers,
  getNextRegistrationNumber,
  getRegistryEntries,
  getRegistryStats,
} = registryEndpoints;
const { baseURL } = apiConfig;

const RegistriesService = {
  async getRegistryStats(registryId) {
    const getRegistryStatsUrl = `${baseURL}${getRegistryStats.GET(registryId)}`;
    return await http
      .get(
        getRegistryStatsUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async getRegistryEntries(filters) {
    const getRegistryEntriesUrl = `${baseURL}${getRegistryEntries.GET()}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;

    return await http
      .post(getRegistryEntriesUrl, filters, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .catch((error) => {
        return error;
      });
  },
  async getNextRegistrationNumber(registryId) {
    const getNextRegistrationNumberUrl = `${baseURL}${getNextRegistrationNumber.GET(
      registryId
    )}`;

    return await http
      .get(
        getNextRegistrationNumberUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async getReservedNumbers(registryId) {
    const getReservedNumbersUrl = `${baseURL}${getReservedNumbers.GET(
      registryId
    )}`;
    return await http
      .get(
        getReservedNumbersUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async addNewReservedNumbers(newReservedNumbers) {
    const getAddReservedNumbersUrl = `${baseURL}${addReservedNumbers.GET}`;
    return await http
      .post(getAddReservedNumbersUrl, newReservedNumbers, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .catch((error) => {
        return error;
      });
  },

  async getRegistries() {
    const getAllRegistriessUrl = `${baseURL}${getRegistries.GET()}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;

    return await http
      .get(
        getAllRegistriessUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${store.state.token}`,
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
};

export default RegistriesService;
