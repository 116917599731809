export default Object.freeze({
  getRegistries: {
    GET: () => {
      return `registries`;
    },
  },
  addReservedNumbers: {
    GET: `registries/reservedNumbers`,
  },
  getReservedNumbers: {
    GET: (registryId) => {
      return `registries/${registryId}/reservedNumbers`;
    },
  },
  getNextRegistrationNumber: {
    GET: (registryId) => {
      return `registries/${registryId}/nextRegistrationNumber`;
    },
  },
  getRegistryStats: {
    GET: (registryId) => {
      return `registries/${registryId}/stats`;
    },
  },
  getRegistryEntries: {
    GET: () => {
      return `registries/entries`;
    },
  },
});
